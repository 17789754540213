
.a-contact-box {
  position: absolute;
  bottom: 9%;
  left: 34.5%;
  width: 35rem;
  max-width: 100%;
  padding: 4.5rem 3rem 2rem;
  border-radius: .5rem;
  background-color: #fff;
  box-shadow: 0 .3rem 5rem 0 rgba(0, 0, 0, 0.2);

  @include respondDf(beforeMacbook) {
    bottom: 2%;
  }
  @media only screen and (max-width: 1366px) {
    left: 26%;
    padding: 3.5rem 2rem 1rem;
  }
  @media only screen and (max-width: 1024px) {
    bottom: 15%;
    left: 22%;

    @media screen and (orientation:landscape) {
      bottom: 2%;
    }
  }
  @media only screen and (max-width: 767px) {
    bottom: 10rem;
    left: 2rem;
  }
}

.a-contact-box__inner {
  display: flex;
}

.a-contact-box__col {
  flex: 0 0 50%;
  padding: 0 .5rem;
}

#contactBoxAddress {

  &.first {
    p:last-of-type {display: none;}
  }
  &.last {
    p:first-of-type {display: none;}
  }
}

.a-contact-box a {

  &:link,
  &:visited {
    color: $color-5;
  }
}

.a-contact-box p {
  margin: 0 0 1.5rem;
  font-size: 1.4rem;
  line-height: 1.429;

  &:last-of-type {
    margin: 0;
  }
}

.a-contact-box__switch {
  display: inline-block;
  position: absolute;
  top: 1rem;
  right: 6.5rem;
  height: 2rem;
  width: 3.5rem;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 2rem;
    color: $color-5;
  }

  &::before {
    content: 'Praha';
    left: -4.3rem;
  }
  &::after {
    content: "Hoštice";
    right: -5.1rem;
  }
}

.a-contact-box__switch-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.a-contact-box__switch-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-5;
  transition: .4s;
  border-radius: 3.4rem;
}

.a-contact-box__switch-slider::before {
  position: absolute;
  content: '';
  height: 1.5rem;
  width: 1.5rem;
  left: .3rem;
  bottom: .3rem;
  background-color: $color-4;
  transition: .4s;
  border-radius: 50%;
}

.a-contact-box__switch-checkbox:checked + .a-contact-box__switch-slider::before {
  transform: translateX(1.4rem);
}
