
// mobile first
@mixin respondMf($breakpoint) {
  @each $breakpointName, $breakpointPx in $grid-breakpoints-mf {
    @if $breakpoint == $breakpointName {
      @media only screen and (min-width:$breakpointPx) {
        @content;
      }
    }
  }
}

// desktop first
@mixin respondDf($breakpoint) {
  @each $breakpointName, $breakpointPx in $grid-breakpoints-df {
    @if $breakpoint == $breakpointName {
      @media only screen and (max-width:$breakpointPx) {
        @content;
      }
    }
  }
}

// IE selector
@mixin IESelector() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}