// mf - mobile first
$grid-breakpoints-mf: (
   sm: 36em, // 576px
   md: 48em, // 768px
   lg: 62em, // 992px
   xlg: 75em // 1200px
);

// df - desktop first
$grid-breakpoints-df: (
  fullHD: 1980px,
  beforeFullHd: 1880px,
  xl: 1600px,
  beforeMacbook: 1440px,
  xlg: 1199px,
  lg: 991px,
  md: 767px,
  sm: 575px
);

// colors
$color-1: #00705b;
$color-2: #c2d943;
$color-3: #303030;
$color-4: #e2f2ff;
$color-5: #00468e;
$color-6: #979797;

// paths
$path-image: '../img';

$font-family-1: 'Open Sans', sans-serif;
$font-family-2: 'Noto Serif', serif;