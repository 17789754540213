
.a-section--stabiluren {
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  padding: 7rem 14rem;

  @include respondDf(beforeFullHd) {
    height: auto !important;
  }

  @include respondDf(sm) {
    padding: 8rem 0;
  }

  h2 {
    font-size: 3.6rem;
    max-width: 80rem;
    margin: 0 auto 2rem;
    text-align: center;
    line-height: 1.1;
    color: $color-5;

    @include respondDf(sm) {
      font-size: 2.4rem;
    }
  }

  & h2 ~ h3 {
    margin-top: 0;
  }

  h3 {
    margin-bottom: 4rem;
    font-size: 3.6rem;
    color: $color-5;
  }

  .a-col--5 {
    display: flex;
    align-items: center;

    @include respondDf(md) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }

    img {

      @include respondDf(md) {
        max-width: 100%;
        margin: 0 0 3rem;
      }
    }
  }

  .a-col--7 {

    @include respondDf(md) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}

.a-section--about-stabiluren {

  .a-row {
    align-items: center;
  }

  .a-col--6 {

    @include respondDf(beforeFullHd) {
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
      margin-right: 0;
    }

    @include respondDf(lg) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }

  .a-col--5 {

    @include respondDf(beforeFullHd) {
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
    }

    @include respondDf(lg) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }
  }

  h2 {
    line-height: 1;
    margin: 0 0 5rem
  }

  h3 {
    color: #000;

    @include respondDf(lg) {
      text-align: center;
    }
  }

  ul {
    font-size: 2.6rem;
    line-height: 1.38;

    @include respondDf(beforeFullHd) {
      font-size: 2.1rem;
    }
  }

  li::before {
    top: 1rem;
    background-image: url('#{$path-image}/layout/list-blue.png');

    @include respondDf(beforeFullHd) {
      top: .7rem;
    }
  }
}

.a-section--davkovani {
  text-align: left;
  align-items: center;
  padding: 7rem;

  @include respondDf(beforeMacbook) {
    padding: 3rem;
  }

  @include respondDf(sm) {
    padding: 8rem 0 10rem;
  }
}

.a-section--davkovani {

  .a-row {
    align-items: center;
  }

  .a-col--7 {

    @include respondDf(beforeFullHd) {
      margin-left: 0;
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
    }

    @include respondDf(lg) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }

    h2 {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  .a-col--5 {
    left: 5rem;

    @include respondDf(beforeFullHd) {
      flex: 0 0 50%;
      width: 50%;
      max-width: 50%;
    }

    @include respondDf(beforeMacbook) {
      left: 0;
    }

    @include respondDf(lg) {
      left: 0;
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }

    h2, p {
      text-align: left;
    }

    h2 {
      margin: 0 0 1rem;
    }
  }

  h2 {
    text-align: left;
    font-size: 3.6rem;
    line-height: 1.333;
    color: $color-5;

    @include respondDf(beforeFullHd) {
      font-size: 3rem;
    }

    @media only screen and (max-width: 767px) {
      font-size: 2.4rem;
    }
  }

  h2 ~ h2 {
    margin-top: 0;
  }

  h2 img {
    width: 17rem;

    @include respondDf(beforeFullHd) {
      width: 14rem;
    }
  }

  h2 + p {
    font-size: 2rem;
    color: $color-3;
  }

  ul {
    font-size: 2.6rem;
    line-height: 1.38;

    @include respondDf(beforeFullHd) {
      font-size: 2.1rem;
    }
  }

  li::before {
    top: 1rem;
    background-image: url('#{$path-image}/layout/list-blue.png');
  }
}

.a-section--davkovani .a-col--5 {

  table {
    width: 100%;
    text-align: left;
    font-size: 1.6rem;
    color: #303030;
    border-collapse: collapse;
    border-top: 1px solid #00468e;
  }

  table th {
    background-color: rgba(0, 70, 142, .1);
  }

  table td,
  table th {
    padding: 1rem;
    border-bottom: 1px solid #00468e;

    @media only screen and (max-width: 767px) {
      word-break: break-all;
    }
  }

  p {
    margin: 2rem 0 0;
    font-size: 1.4rem;
  }
}

.a-davkovani-img-container {
  position: relative;
  height: 35.9rem;
  max-width: 63.6rem;
  transform-origin: left;
  overflow: hidden;
  margin: 4rem auto 0;

  @include respondDf(beforeMacbook) {
    max-width: 45rem;
    height: 25.5rem;
  }

  @media only screen and (max-width: 1280px) {
    max-width: 41rem;
    height: 23rem;
  }

  @media only screen and (max-width: 767px) {
    max-width: 29rem;
    height: 16.4rem;
  }

  .active & {
    width: 0;
    animation: 5s diagram forwards;
  }
}

.a-davkovani-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 63.6rem;
  transition: 2s width;

  @include respondDf(beforeMacbook) {
    width: 45rem;
  }

  @media only screen and (max-width: 1280px) {
    width: 41rem;
  }

  @media only screen and (max-width: 767px) {
    width: 29rem;
  }
}

.a-section--stabiluren h2 img,
.a-section--about-stabiluren h2 img {
  width: 26.8rem;
}

.a-stabiluren-img-container {
  position: relative;
  height: 31rem;
  max-width: 62rem;
  transform-origin: left;
  overflow: hidden;
  margin: 4rem auto 0;

  @include respondDf(beforeMacbook) {
    max-width: 50rem;
    height: 25rem;
  }

  @media only screen and (max-width: 767px) {
    max-width: 29rem;
    height: 14.5rem;
  }

  .active & {
    width: 0;
    animation: 5s diagram forwards;
  }
}

.a-stabiluren-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 62rem;
  transition: 2s width;

  @include respondDf(beforeMacbook) {
    width: 50rem;
  }

  @media only screen and (max-width: 767px) {
    width: 29rem;
  }
}
