
.a-popup-trigger {
  display: none;
}

.a-popup-button {
  position: absolute;
  z-index: 999;
  left: 4rem;
  bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  transition: .25s background-color;
  will-change: background-color;

  @include respondDf(md) {
    left: 2rem;
    bottom: 2rem;
  }

  &:hover {
    background-color: $color-1;
  }
}

.a-popup-button__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5.6rem;
  height: 5.6rem;
  border: .3rem solid $color-1;
  border-radius: 50%;
  font-family: $font-family-2;
  font-size: 3.6rem;
  font-weight: 300;
  color: $color-1;
  transition: .25s border-color, .25s color;
  will-change: border-color, color;

  .a-popup-button:hover & {
    border-color: #fff;
    color: #fff;
  }
}

.a-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: flex-start;
  background-color: rgba(black, .502);
  z-index: 1000;

  display: none;

  .a-popup-trigger:checked ~ & {
    display: flex;
  }
}

.a-popup__inner {
  width: 75%;
  height: auto;
  margin: auto;

  @include respondDf(md) {
    width: 90%;
  }
}

.a-popup__text {
  height: 70vh;
  padding: 4rem 7rem;
  overflow: auto;
  background-color: #fff;

  @include respondDf(md) {
    height: 85vh;
    padding: 2rem;
  }
}

.a-popup__text h2 {
  text-align: left;
  font-weight: 300;

  @include respondDf(md) {
    margin: 0 0 2rem;
    font-size: 2.6rem;
  }
}

.a-popup__text p {
  margin: 0 0 5rem;
  font-size: 2.2rem;
  line-height: 2;

  @include respondDf(md) {
    margin: 0 0 2rem;
    font-size: 1.4rem;
    line-height: 1.4;
  }
}

.a-popup-closer {
  display: none;
}

.a-popup-closer-label {
  display: block;
  width: 100%;
  padding: 1rem;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 700;
  color: #fff;
  cursor: pointer;

  svg {
    position: relative;
    top: .7rem;
  }
}

