
.a-contact {
  display: flex;
  align-items: center;
  margin: 0 0 1.5rem;
  font-family: $font-family-2;
  font-size: 1.8rem;
  font-weight: 700;
  transform: scale(1,1);
  transition: .25s all;

  &:link,
  &:visited {
    text-decoration: none;
    color: $color-5;
  }

  &[href*=mailto] {
    text-decoration: underline;
    img {
      position: relative;
      top: .2rem;
    }
  }

  &:hover,
  &:active {
    transform: scale(1.1,1.1);
  }

  img {
    margin: 0 3rem 0 0;

    @include respondDf(md) {
      margin: 0 1rem 0 0;
    }
  }
}


