
@keyframes spaceboots {
  0% { transform: translate(2px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(0px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(2px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(2px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes grassGrowing {
  0% { top: 100%; opacity: 0; }
  100% { top: 0; opacity: 1;}
}

.a-grass {
  position: relative;
  top: 100%;
  opacity: 0;
  z-index: -1;
  // animation: 10s spaceboots infinite;
}

.a-section.active .a-grass {
  z-index: 1;
  animation: //10s spaceboots infinite,
            3s grassGrowing forwards;
}
