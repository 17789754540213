
.a-nav-fixed {
  position: fixed;
  top: 50%;
  right: 5.5rem;
  z-index: 100;
  transform: translateY(-50%);
  margin: -1rem 0;

  @media only screen and (max-width: 1024px) {
    right: 1.5rem;
  }
  @include respondDf(md) {
    display: none;
  }
}

.a-nav-fixed a {
  position: relative;
  display: block;
  width: 1.4rem;
  height: 1.4rem;
  margin: 1.5rem 0;
  border-radius: 50%;
  color: transparent;
  background-color: #71797f;
  transition: .25s all;

  &::after {
    content: '';
    position: absolute;
    top: -.6rem;
    left: -.6rem;
    width: 2.6rem;
    height: 2.6rem;
    border: .2rem solid $color-2;
    border-radius: 50%;
    transform: scale(0, 0);
    opacity: 0;
    transition: .25s all;
  }

  &.active,
  &:hover {
    background-color: $color-1;

    &::after {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
}

