
.a-logo {
  display: block;
  width: 17.7rem;
  transform: scale(1,1);
  transition: all .25s;

  @include respondDf(beforeFullHd) {
    width: 12rem;
  }

  @include respondDf(md) {
    width: 8rem;
  }

  &:hover {
    transform: scale(1.1, 1.1);
  }

  img {
    max-width: 100%;
  }
}
