
.a-menu {
  position: fixed;
  top: 0;
  right: 0;
  display: inline-block;
  height: 100vh;
  min-width: 31.55rem;
  overflow: auto;
  padding: 10rem 3rem 11rem;
  z-index: 200;
  background-color: #fff;
  box-shadow: -.4045rem .2939rem 5rem 0 rgba(black, 0.15);
  transition: .25s transform;

  @media only screen and (max-height: 720px) {
    padding: 10rem 3rem 3rem;
  }

  @include respondDf(beforeFullHd) {
    padding: 3rem;
  }

  @include respondDf(xlg) {
    padding: 7rem 3rem 3rem;
  }

  @media only screen and (max-width: 1024px) {
    transform: translateX(150%);
  }

  @include respondDf(md) {
    z-index: 999;
    min-width: .1rem;
  }
}

.a-menu.open {
  @media only screen and (max-width: 1024px) {
    transform: translateX(0);
  }
}

.a-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.a-menu li {
  max-width: 26rem;
}

.a-menu a:not(.a-lang__item) {
  position: relative;
  display: block;
  font-size: 1.8rem;
  line-height: 6rem;
  border-bottom: .1rem solid #e3e3e3;

  @media only screen and (max-width: 400px) {
    line-height: 4.5rem;
  }

  &:link,
  &:visited {
    color: $color-3;
    text-decoration: none;
  }

  &.active,
  &:hover,
  &:active {
    color: $color-1;
    font-weight: 700;
  }
}


.a-menu a:not(.a-lang__item)::before {
  content: '';
  position: absolute;
  top: 2rem;
  left: -4rem;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: .9rem 1.1rem .9rem 0;
  border-color: transparent #fff transparent transparent;
  transform: scale(0,0);
  transition: .25s transform;
  will-change: transform;

  @include respondDf(md) {
    display: none;
  }
}

.a-menu a.active::before {
  transform: scale(1,1);
}

.a-menu__logo {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 21rem;
  max-width: 100%;
  margin: 4rem auto 0;

  @media only screen and (max-height: 720px) {
    position: static;
    transform: translateX(0);
  }
}

