
.a-subtitle p {
  font-size: 4rem;
  line-height: 1.2;
  color: $color-3;

  @include respondDf(beforeFullHd) {
    font-size: 3rem;
  }

  @media only screen and (max-width: 1366px) {
    font-size: 2.4rem;
  }

  @include respondDf(md) {
    font-size: 1.2rem;
    line-height: 1.4;
  }
}

.a-subtitle--3 p {
  font-size: 3rem;
  line-height: 1.5;

  @include respondDf(beforeFullHd) {
    font-size: 1.6rem;
  }
}

.a-subtitle--26 p {
  font-size: 2.6rem;
  line-height: 1.4;

  @include respondDf(beforeFullHd) {
    font-size: 2rem;
  }
}

.a-subtitle small {
  font-size: 3rem;

  @include respondDf(beforeFullHd) {
    font-size: 2.4rem;
  }

  @media only screen and (max-width: 767px) {
    font-size: 1.2rem;
    margin: 2rem 0 0;
    line-height: 1.2;
  }
}
