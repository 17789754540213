html {
  font-size: 62.5%; // 1rem = 10px
}

body {
  font-size: 1.6rem;
  font-family: $font-family-1;
}

html,
body {
  overflow: hidden;

  @include respondDf(beforeFullHd) {
    overflow: auto;
  }
}

input:focus,
button:focus,
textarea:focus {
  outline: none;
}