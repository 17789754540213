.a-container {
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.a-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
}

.a-col {
  flex: 0 0 100%;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 1px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  &--1 {
    flex: 0 0 8.3333%;
    width: 8.3333%;
    max-width: 8.3333%;
  }

  &--2 {
    flex: 0 0 16.6667%;
    width: 16.6667%;
    max-width: 16.6667%;
  }

  &--3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }

  &--4 {
    flex: 0 0 33.3333%;
    width: 33.3333%;
    max-width: 33.3333%;
  }

  &--5 {
    flex: 0 0 41.6667%;
    width: 41.6667%;
    max-width: 41.6667%;
  }

  &--6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }

  &--7 {
    flex: 0 0 58.3333%;
    width: 58.3333%;
    max-width: 58.3333%;
  }

  &--8 {
    flex: 0 0 66.6667%;
    width: 66.6667%;
    max-width: 66.6667%;
  }

  &--9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }

  &--10 {
    flex: 0 0 83.3333%;
    width: 83.3333%;
    max-width: 83.3333%;
  }

  &--11 {
    flex: 0 0 91.6667%;
    width: 91.6667%;
    max-width: 91.6667%;
  }

  &--right-1 {
    margin-right: 8.3333%;
  }

  &--right-2 {
    margin-right: 16.6667%;
  }

  &--right-3 {
    margin-right: 25%;
  }

  &--right-4 {
    margin-right: 33.3333%;
  }

  &--right-5 {
    margin-right: 41.6667%;
  }

  &--right-6 {
    margin-right: 50%;
  }

  &--right-7 {
    margin-right: 58.3333%;
  }

  &--right-8 {
    margin-right: 66.6667%;
  }

  &--right-9 {
    margin-right: 75%;
  }

  &--right-10 {
    margin-right: 83.3333%;
  }

  &--right-11 {
    margin-right: 91.6667%;
  }

  &--left-1 {
    margin-left: 8.3333%;
  }

  &--left-2 {
    margin-left: 16.6667%;
  }

  &--left-3 {
    margin-left: 25%;
  }

  &--left-4 {
    margin-left: 33.3333%;
  }

  &--left-5 {
    margin-left: 41.6667%;
  }

  &--left-6 {
    margin-left: 50%;
  }

  &--left-7 {
    margin-left: 58.3333%;
  }

  &--left-8 {
    margin-left: 66.6667%;
  }

  &--left-9 {
    margin-left: 75%;
  }

  &--left-10 {
    margin-left: 83.3333%;
  }

  &--left-11 {
    margin-left: 91.6667%;
  }

  &--auto {
    margin-left: auto;
    margin-right: auto;
  }
}
