p {
  margin: 0 0 2rem;
  font-size: 2.4rem;
  line-height: 1.5;
  color: $color-3;

  @include respondDf(md) {
    font-size: 1.4rem;
  }
}

a {
  &:link,
  &:visited {
    color: $color-1;
    transition: .25s all;
  }
}

h1,
h2 {
  margin: 0 0 8rem;
  text-align: center;
  font-family: $font-family-2;
  font-size: 5.6rem;
  font-weight: 700;
  color: $color-1;

  @include respondDf(beforeFullHd) {
    font-size: 4rem;
  }

  @include respondDf(md) {
    margin: 0 0 2rem;
    font-size: 3.6rem;
    line-height: 1.2;
  }
}

h2 {
  margin: 0 0 3rem;
  line-height: 1.6;

  @include respondDf(md) {
    line-height: 1.2;
  }
}

h3 {
  margin: 0;
  text-align: left;
  font-family: $font-family-2;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.5;
  color: $color-1;

  @include respondDf(beforeFullHd) {
    font-size: 2.4rem;
  }
}
