
.a-section--kontakt {
  position: relative;
  align-items: flex-start;
  padding: 6rem 2rem 2rem;

  @media only screen and (max-width: 1440px) {
    background-color: #d3e8f9;
  }

  @media only screen and (max-width: 1024px) {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 768px) {
    align-items: flex-start;
    padding: 12rem 0;
  }

  @media only screen and (max-width: 767px) {
    background-color: #d5e8f7;
    padding: 12rem 0 6rem;
    overflow: hidden;
  }

  @include respondDf(md) {
    flex-wrap: wrap;
  }

  a:link,
  a:visited {
    color: $color-5;
  }

  h2 {
    margin: 0 0 2rem;
    text-align: left;
    font-size: 3.6rem;
    line-height: 1.333;
    color: $color-5;
  }

  h3 {
    margin: 0 0 1rem;
    font-size: 3rem;
    color: $color-5;
  }

  p {
    font-size: 1.5rem;
    line-height: 2.4;
  }

  > img {
    object-position: center bottom;
    font-family: 'object-fit:cover;object-position:center bottom;';

    @media only screen and (max-width: 1440px) {
      top: 0;
      height: auto;
    }

    @media only screen and (max-width:767px) {
      object-fit: contain;
      width: 200%;
      left: -50%;
      height: auto;
      font-family: 'object-fit:contain;object-position:center top;';
    }
  }

  .a-col--7,
  .a-col--5 {

    @include respondDf(md) {
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      margin-bottom: 3rem;
    }
  }

  li::before {
    display: none;
  }

  table {
    width: 100%;
    text-align: left;
    font-size: 1.6rem;
    color: #303030;

    @media only screen and (max-width:767px) {
      font-size: 1.3rem;
    }
  }

  table td,
  table th {
    vertical-align: top;
    padding: .5rem;

    @media only screen and (max-width: 767px) {
      word-break: break-word;
    }
  }

  .a-col--8 table tr td:first-of-type {
    color: #f9b000;
    min-width: 2.5rem;
  }

  table tr td:last-of-type {
    white-space: nowrap;
    font-weight: 700;
  }

  .a-contact-table {
    padding: 2rem;
    border-radius: .5rem;
    box-shadow: 0 0.3rem 5rem 0 rgba(0,0,0,0.2);
    background-color: #fff;

    @include respondDf(lg) {
      max-width: 95%;
      margin: auto;
    }
  }

  .a-diagram {
    visibility: hidden;
    margin-bottom: 18rem;

    @media only screen and (max-width: 1366px) {
      margin-bottom: 10rem;
    }
    @media only screen and (max-width: 991px) {
      margin-bottom: 0;
    }
  }
}

.a-section--kontakt .a-col--4:last-of-type {
  position: relative;

  table {
    margin-top: 10%;
  }
}

.a-section--kontakt .a-col--4:last-of-type::before {
  content: '';
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  width: .1rem;
  left: -1.5rem;
  background-color: #eee;

  @media only screen and (width:1440px) {
    left: 0;
  }

  @media only screen and (max-width:767px) {
    top: 1rem;
    left: 1rem;
    right: 1rem;
    width: calc(100% - 2rem);
    height: .1rem;
  }
}


.a-section--kontakt .a-col--6 {

  @media only screen and (max-width:1366px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}

.a-section--kontakt .a-col--8 {

  @media only screen and (max-width:1366px) {
    flex: 0 0 60%;
    width: 60%;
    max-width: 60%;
  }

  @media only screen and (max-width:767px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }

  table td:nth-of-type(2) {
    @media only screen and (max-width:1366px) {
      width: 60%;
    }
  }
}

.a-section--kontakt .a-col--4 {

  @media only screen and (max-width:1366px) {
    flex: 0 0 40%;
    width: 40%;
    max-width: 40%;
  }

  @media only screen and (max-width:767px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
}
