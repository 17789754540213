
.a-section {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 14rem;

  @include respondDf(xlg) {
    padding: 7rem 4rem;
  }

  @media only screen and (max-width: 1024px) {
    height: auto !important;
  }

  @include respondDf(md) {
    padding: 8rem 0 10rem;
  }

  h2 ~ h2,
  h2 ~ h3 {
    margin-top: 5rem;
  }
}

.a-section > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit:cover;object-position:center center;';
}

.a-section__next {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 6rem;
  height: 7.5rem;
  z-index: 10;
  cursor: pointer;

  @include respondDf(beforeMacbook) {
    width: 4rem;
    height: 5.5rem;
  }

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &::before,
  .a-section__next-text {
    content: "" attr(data-text) "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 0 2.5rem;
    white-space: nowrap;
    font-family: $font-family-2;
    font-weight: 700;
    font-size: 2.4rem;
    color: $color-3;
    cursor: pointer;

    @include respondDf(beforeFullHd) {
      font-size: 2rem;
    }

    @media only screen and (max-width: 1024px) {
      margin: 0 0 .5rem;
    }

    @include respondDf(md) {
      white-space: normal;
      width: 25rem;
      text-align: center;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 2rem;
    left: 2.3rem;
    border: solid #989999;
    border-width: 0 .2rem .2rem 0;
    display: inline-block;
    padding: .6rem;
    text-decoration: none;
    transform: rotate(45deg);
    transition: .25s all;

    @include respondDf(beforeMacbook) {
      top: 1.6rem;
      left: 1.5rem;
      padding: .4rem;
    }
  }

  &:link,
  &:visited {
    text-decoration: none;
  }

  &:hover {
    &::after {
      top: 2.4rem;

      @include respondDf(beforeMacbook) {
        top: 2.2rem;
      }
    }
  }
}

.a-section ul {
  margin: 0 0 3rem;
  padding: 0 0 0 2rem;
  list-style: none;
  font-size: 1.6rem;
  line-height: 1.77;
  color: $color-3;
}

.a-section li {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -2rem;
    top: 1.5rem;
    display: inline-block;
    width: 1.2rem;
    height: 1.8rem;
    background-image: url('#{$path-image}/layout/list-blue.png');
    background-repeat: no-repeat;
    background-size: 1.2rem 1.8rem;

    @include respondDf(beforeFullHd) {
      top: 1.1rem;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.a-section {

  h1, h2, h3, p, ul {
    opacity: 0;

    @include respondDf(md) {
      opacity: 1;
    }
  }

  &.active {
    h1, h2, h3, p, ul {
      animation: .5s fadeIn forwards;
    }
  }
}
