
.a-hamburger {
  position: absolute;
  top: .5rem;
  right: 2rem;
  width: 5rem;
  height: 5rem;
  padding: 0;
  border-radius: 50%;
  background-color: $color-1;
  user-select: none;

  display: none;

  @media only screen and (max-width: 1024px) {
    top: 0;
    display: inline-block;
  }
  @include respondDf(md) {
    top: -.5rem;
  }
}

.a-hamburger .hamburger-box {
  top: .2rem;
  width: 2.2rem;
  height: 1.7rem;
}

.a-hamburger .hamburger-inner,
.a-hamburger .hamburger-inner::before,
.a-hamburger .hamburger-inner::after {
  width: 2.2rem;
  height: .3rem;
  background-color: #fff;
}

.a-hamburger .hamburger-inner::before {
  top: .7rem;
}

.a-hamburger .hamburger-inner::after {
  top: 1.4rem;
}


// active part
.a-hamburger.hamburger.is-active .hamburger-inner,
.a-hamburger.hamburger.is-active .hamburger-inner::before,
.a-hamburger.hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}

.a-hamburger.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -1.4rem, 0) rotate(-270deg);
}

.a-hamburger.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, .8rem, 0) rotate(135deg);
}