
@keyframes diagram {
  0% { width: 0; }
  100% { width: 100%; }
}

.a-diagram {
  position: relative;
  transform-origin: left;
  height: 30rem;
  margin: 0 0 0 5%;
  overflow: hidden;

  @include respondDf(md) {
    height: auto;
    margin-left: 0;
    margin-bottom: 4rem;
  }

  @media only screen and (max-width: 1366px) {
    max-width: 100%;
  }

  img {
    display: block;

    @include respondDf(lg) {
      position: static;
      max-width: 100%;
    }
  }
}

.a-diagram--without {
  height: 25rem;
  margin: -1rem 0 0 4rem;

  @media only screen and (max-width: 1366px) {
    margin: 2rem 0 0 4rem;
  }

  @include respondDf(lg) {
    height: auto;
    margin: 1rem 0 5rem;
    text-align: center;
  }

  img {
    width: 45rem;
  }
}

.a-diagram--with {
  height: 32rem;
  margin: 0 0 0 4rem;

  @include respondDf(lg) {
    height: auto;
    margin: 1rem 0 5rem;
    text-align: center;
  }

  img {
    width: 45rem;
  }
}

.a-diagram--safety {
  margin: 0 auto 2rem;

  @include respondDf(lg) {
    text-align: center;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 50rem;
  }
}

.a-section.active .a-diagram {
  animation: 5s diagram forwards;

  max-width: 50rem;

  @include respondDf(lg) {
    max-width: 100%;
    animation: none;
  }
}
