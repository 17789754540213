
.a-section--product {

  @include respondDf(beforeMacbook) {
    padding: 10rem 7rem 12rem;
  }

  @include respondDf(sm) {
    padding: 8rem 0;
  }
}

.a-product-img-container {
  position: relative;
  height: 33.2rem;
  max-width: 72rem;
  transform-origin: left;
  overflow: hidden;
  margin: auto;

  @media only screen and (max-width: 767px) {
    height: 13.3rem;
    max-width: 29rem;
    margin: 3rem auto;
  }

  .active & {
    width: 0;
    animation: 5s diagram forwards;
  }
}

.a-product-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 72rem;
  transition: 2s width;

  @media only screen and (max-width: 767px) {
    width: 29rem;
  }
}

.a-section--product li::before {
  top: .5rem;
}

