
.a-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.a-list-item {
  position: relative;
  left: -1rem;
  display: flex;
  align-items: flex-start;
  flex: 0 0 33.3333%;

  @include respondDf(beforeMacbook) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin-bottom: 2rem;
  }
}

.a-list-item__left {
  flex: 0 0 6rem;
}

.a-list-item__num {
  position: relative;
  top: .3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: .2rem solid #4c7daf;
  font-family: $font-family-2;
  font-size: 1.8rem;
  font-weight: 700;
  color: $color-5;
}

.a-list-item__inner ul {
  @media only screen and (max-width: 767px) {
    margin: 0;
  }
}

