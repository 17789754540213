
@keyframes float {
  0% {
    transform: translatey(0) rotate(0.02deg);
  }
  50% {
    transform: translatey(-2rem) rotate(0.02deg);
  }
  100% {
    transform: translatey(0) rotate(0.02deg);
  }
}

@keyframes floatReverse {
  0% {
    transform: translatey(-2rem) rotate(0.02deg);
  }
  50% {
    transform: translatey(0) rotate(0.02deg);
  }
  100% {
    transform: translatey(-2rem) rotate(0.02deg);
  }
}

.a-bubbles {
  margin: 4rem 0 2rem;
  max-width: 100%;
  animation: float 10s ease-in-out infinite;

  @include respondDf(beforeMacbook) {
    margin: 2rem auto 0;
    width: 35rem;
    display: none;
  }
}

.a-bubbles-1 {
  position: absolute;
  top: 0;
  left: 25%;
  animation: float 10s ease-in-out infinite;
}

.a-bubbles-2 {
  position: absolute;
  top: 35%;
  left: 5%;
  animation: floatReverse 10s ease-in-out infinite;
}

.a-bubbles-3 {
  position: absolute;
  top: 80%;
  left: 0;
  animation: float 10s ease-in-out infinite;

  @include respondDf(beforeMacbook) {
    display: none;
  }
}

.a-bubbles-4 {
  position: absolute;
  top: -20%;
  left: 0;
  animation: float 10s ease-in-out infinite;
}

.a-bubbles-5 {
  position: absolute;
  top: -15%;
  left: 5%;
  animation: floatReverse 10s ease-in-out infinite;

  @include respondDf(md) {
    left: 0;
  }
}
