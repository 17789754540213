
.a-lang {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6rem 0 2rem;
}

.a-lang__item {
  margin: 0 1.4rem;
  text-decoration: none;
  font-family: $font-family-2;
  font-size: 1.8rem;
  font-weight: 400;
}

.a-lang__item:link,
.a-lang__item:visited {
  color: $color-6;
}

.a-lang__item:hover,
.a-lang__item:active,
.a-lang__item--active:link,
.a-lang__item--active:visited {
  color: $color-3;
}

