
.u-ta-c {
  text-align: center;
}

.u-ta-r {
  text-align: right;
}

.u-ta-l {
  text-align: left;
}